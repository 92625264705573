import AuthService from "../remote/services/AuthService";

const ENVIRONMENT = {
  DEV: 'dev',
  PROD: 'prod',
  DEMO: 'demo',
};

export default class ConfigurationSingleton {
    private static instance: ConfigurationSingleton | null;

    // Initializing important variables
    constructor(domain?: string) {
    }

    static getInstance() {
        if (!this.instance) {
            this.instance = new ConfigurationSingleton();
            return this.instance;
        } else {
            return this.instance;
        }
    }

    getLogo(){
        let logo = require(`../assets/img/img_default.png`);
        return logo;
    }

    getLogoHeader(){
      let logo = require(`../assets/img/img_header_navbar.png`);
      return logo;
    }


    canViewSystemUsersPage(){
      return (AuthService.getInstance().isUserAdmin())
    }

    canViewAuditingPage(){
      return (AuthService.getInstance().isUserAdmin())
    }

    canViewSendMessagePage(){
      return (AuthService.getInstance().isUserAdmin() || AuthService.getInstance().isUserSender())
    }

    canViewListCampainPage(){
      return (AuthService.getInstance().isUserAdmin())
    }

    canViewContactsPage(){
      return (AuthService.getInstance().isUserAdmin() || AuthService.getInstance().isUserEditor())
    }



    getBackEndPath(){
        //console.log(`getBackEndPath - called`)
      switch (process.env.REACT_APP_ENV) {
        case ENVIRONMENT.DEV:
          //return 'http://54.171.247.226/gestione-contatti'
          return '/gestione-contatti'

        case ENVIRONMENT.PROD:
          return '/gestione-contatti'
      }
    }

}
