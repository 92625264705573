
const CONSTANTS =
  {

    COLORS:{
      getPrimaryColor(){ return "#112d3c"},
      getSecondaryColor(){ return "#AE8C46"},
      getLightColor(){ return "#F3EEE7"}
    },
    USER_TYPES:{
      ADMIN:"dmnjqh4987gyhwr97w",
      SENDER:"sddasdlkajsfkhqoiryqo38r7802hj",
      EDITOR:"AHDJASHF9832YHhca97syfh3R",
      NEGOZIANTE:"DKSHJFLO83URTljhdfl48",
    },

};
export default CONSTANTS;
