import axios, {AxiosRequestConfig, AxiosResponse} from 'axios';
import {cloneDeep, get} from 'lodash';
import Endpoints from '../Endpoints.js';
import qs from 'qs';
import {BackendResponse} from "../types/backend.types";
import CONSTANTS from "../../model/Constants";


interface AuthToken {
    realm_access: {
        roles: string[];
    };
    exp: number;
}

interface User {
    refresh_token: string;
    id: string;
    username: string;
    accountEnabled: string;
    expires: string;
    type: string;
}

export default class AuthService {
    domain: string;
    private static instance: AuthService | null;
    private user: User | undefined;

    // Initializing important variables
    constructor(domain?: string) {
        // @ts-ignore
      this.domain = domain || Endpoints.backendServices

        this.fetch = this.fetch.bind(this)
        this.getProfile = this.getProfile.bind(this)
        this.setToken = this.setToken.bind(this)
        this.login = this.login.bind(this)
        this.getUsername = this.getUsername.bind(this)

        // @ts-ignore
        axios.defaults.crossDomain = true;
        axios.defaults.withCredentials = false;
        axios.defaults.timeout = 240000;
        axios.defaults.headers.post['Content-Type'] = 'application/json';
        axios.defaults.headers.put['Content-Type'] = 'application/json';

        axios.interceptors.response.use(undefined,
            err => {
                let res = err.response;
                //console.log(`interceptor - ${JSON.stringify(err.response)}`)
                const { request, ...errorObject } = res;
                console.log(`request error axios: ${JSON.stringify(request)}`)

                if(res && res.data && res.data.path && res.data.path.startsWith("https://autocomplete.geocoder")){
                  return Promise.reject({...res, _error:err});
                }
                else{
                  if ((res.status === 401 || res.status === 403 || res.status === 498)){
                    console.log(`interceptor ERROR Container - AXIOS INTERCEPTOR RESPONSE - ${res.data.path}`);
                    this.logout();
                    // @ts-ignore
                    window.history.go('/login');
                  }
                  else{
                    return Promise.reject({...res, _error:err});
                  }
                }

            })
    }

    static getInstance() {
        if (!this.instance) {
            this.instance = new AuthService();
            return this.instance;
        } else {
            return this.instance;
        }
    }

    refreshToken = () => {
        const { refresh_token } = this.getProfile();
        const axiosConfig = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization':'Bearer ' + this.getToken(),
            }
        }

        const body = qs.stringify({
            client_id: "talk",
            grant_type: "refresh_token",
            refresh_token,
        })

        return axios.post(Endpoints.loginEndpoint, body, axiosConfig).then(res => {
            this.setToken(res.data.access_token)
            this.setUserProfile(res.data);
            return res;
        })
    }

    checkUserIsAdmin(responseType: string){
      //console.log(`checkUserIsAdmin : ${JSON.stringify(responseType)}`)
      return responseType==="ADMIN"
    }

    checkUserIsNegoziante(responseType: string){
      //console.log(`checkUserIsNegoziante : ${JSON.stringify(responseType)}`)
      return responseType==="NEGOZIANTE"
    }

    checkUserIsOperatore(responseType: string){
      //console.log(`checkUserIsOperatore : ${JSON.stringify(responseType)}`)
      return responseType==="OPERATORE"
    }



    isUserAdmin(){
      let userSaved = JSON.parse(<string>localStorage.getItem('user'));
      return get(userSaved, 'type')===CONSTANTS.USER_TYPES.ADMIN;
    }

    isUserSender(){
      let userSaved = JSON.parse(<string>localStorage.getItem('user'));
      return get(userSaved, 'type')===CONSTANTS.USER_TYPES.SENDER;
    }

    isUserEditor(){
      let userSaved = JSON.parse(<string>localStorage.getItem('user'));
      return get(userSaved, 'type')===CONSTANTS.USER_TYPES.EDITOR;
    }


/*    isUserOperatore(){
      let userSaved = JSON.parse(<string>localStorage.getItem('user'));
      return get(userSaved, 'type')===CONSTANTS.USER_TYPES.OPERATORE;
    }

    isUserNegoziante(){
      let userSaved = JSON.parse(<string>localStorage.getItem('user'));
      return get(userSaved, 'type')===CONSTANTS.USER_TYPES.NEGOZIANTE;
    }

    isUserAssistenteSociale(){
      let userSaved = JSON.parse(<string>localStorage.getItem('user'));
      return userSaved['type']===CONSTANTS.USER_TYPES.ASSISTENTE_SOCIALE;
    }*/


    recuperaPwd(email:string){
      const config: AxiosRequestConfig ={
        headers: {
          'Accept': 'application/json',
          'Content-Type': "text/plain"
        }
      };
      return axios.put(`${Endpoints.recuperaPwdEndpoint}`, email, config)
        .then(function(res){
        return Promise.resolve(res.data);
      }).catch((error) => {
        return Promise.reject(error);
      })
    }


    resetPwd(password:string, token:string){
      let config: AxiosRequestConfig ={
        headers: {
        }
      };

      let body = {
        password:password,
        token:token
      }

      return axios.put(`${Endpoints.resetPwdEndpoint}`, JSON.stringify(body), config)
        .then(function(res){
          return Promise.resolve(res.data);
        }).catch((error) => {
          return Promise.reject(error);
        })
    }

    login(email: string, pwd: string) {
      let body = "username="+email+"&password="+pwd;

        return this.fetch(`${Endpoints.loginEndpoint}`, body)
            .then(function(res){
                //console.log(`Res: ${JSON.stringify(res)}`);
                //console.log(`Res: headers ${JSON.stringify(res.headers)}`);

                if(res && res.data) {

                  /*if(AuthService.getInstance().checkUserIsAdmin(res.data.result[0].type) ||
                    AuthService.getInstance().checkUserIsNegoziante(res.data.result[0].type)
                  ){*/
                    localStorage.setItem('access_token', res.headers.authorization)
                  /*}else{
                    let err={data:{
                        error_description: "Credenziali non valide."
                      }}
                    return Promise.reject(err);
                  }*/
                  //this.setUserProfile(userData)
                }
                return Promise.resolve(res.data);
            }).catch((error) => {
            //console.log(`ERROR + ${error}`);
                return Promise.reject(error);
            })
    }

    loggedIn() {
        // Checks if there is a saved token and it's still valid
        const token = this.getToken() // GEtting token from localstorage
        return !!token // handwaiving here
    }

    setToken(idToken: string) {
        // Saves user token to localStorage
        localStorage.setItem('access_token', idToken)
    }

    getUsername() {
      // Retrieves the user token from localStorage
      //console.log();
      let user = JSON.parse(<string>localStorage.getItem("user"))
      return get(user, 'username', "-");
    }

    getToken() {
        // Retrieves the user token from localStorage
        return localStorage.getItem('access_token')
    }

    logout() {
        localStorage.clear();
    }

    getProfile(): User {

        if(this.user==undefined){
            // @ts-ignore
            this.user=JSON.parse(localStorage.getItem('user'));
        }
        return this.user as User;
    }

    setUserProfile(user: User) {

      let userToSave = cloneDeep(user);
      delete(userToSave.expires)
      delete(userToSave.accountEnabled)

      // @ts-ignore
      userToSave.type=CONSTANTS.USER_TYPES[userToSave.type];

      this.user=userToSave;

      localStorage.setItem('user',JSON.stringify(userToSave));
    }


    getLoggedInHeaders(){
        return {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization':'Bearer ' + this.getToken()
        }
    }

    getLoggedInHeadersMultipar(){
        return {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization':'Bearer ' + this.getToken()
        }
    }


    fetch(url: string, body: any) {
        // performs api calls sending the required authentication headers
        const config: AxiosRequestConfig ={
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        };

        // Setting Authorization header
        // Authorization: Bearer xxxxxxx.xxxxxxxx.xxxxxx
        if (this.loggedIn()) {
            config.headers['Authorization'] = 'Bearer ' + this.getToken()
        }

        return axios.post(url, body,
            config);
    }

    _checkStatus<T>(response: AxiosResponse<BackendResponse<T>>): AxiosResponse<BackendResponse<T>> {
        if (response && response.status  && response.status == 200) { // Success status lies between 200 to 300
            return response;
        }
        else {
            let error = new Error(response.statusText)
            //@ts-ignore
            error.response = response
            throw error
        }
    }
}
