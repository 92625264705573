import React, {Component} from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import './App.scss';
import AuthService from "./remote/services/AuthService";

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/Pages/Login'));
const Register = React.lazy(() => import('./views/Pages/Register'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));

class App extends Component {

  render() {
    return (
      <BrowserRouter basename={"/dashboard"}>
          <React.Suspense fallback={loading()}>
            <Switch>
              <Route exact path="/login" name="Login " render={props => <Login {...props}/>} />
              <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} />
              <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
              <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />

              {/*

              <Route path="/contatti" name="Contatti" render={
                props => {
                  if(AuthService.getInstance().loggedIn())
                    return <DefaultLayout {...props} isNegozio={!AuthService.getInstance().isUserAdmin()} key={1}/>
                  else{
                    return <Login {...props}/>
                  }
                }
              }/>

              <Route path="/contatto" name="Contatti" render={
                props => {
                  if(AuthService.getInstance().loggedIn())
                    return <DefaultLayout {...props} isNegozio={!AuthService.getInstance().isUserAdmin()} key={1}/>
                  else{
                    return <Login {...props}/>
                  }
                }
              }/>

              <Route path="/sendMessage" name="Negozi" render={
                props => {
                  if(AuthService.getInstance().loggedIn())
                    return <DefaultLayout {...props} isNegozio={!AuthService.getInstance().isUserAdmin()} key={1}/>
                  else{
                    return <Login {...props}/>
                  }
                }
              }/>


              <Route path="/users" name="Utenti Sistema" render={
                props => {
                  if(AuthService.getInstance().loggedIn())
                    return <DefaultLayout {...props} isNegozio={!AuthService.getInstance().isUserAdmin()} key={1}/>
                  else{
                    return <Login {...props}/>
                  }
                }
              }/>

*/}





{/*              <Route path="/pratiche" name="Pratiche" render={
                props => {
                  if(AuthService.getInstance().loggedIn() && AuthService.getInstance().isEnabledToViewPratiche())
                    return <DefaultLayout {...props} isNegozio={false} key={3}/>
                  else{
                    return <Login {...props}/>
                  }
                }
              }/>*/}

              <Route path="/" name="Home" render={
                props => {
                  if(AuthService.getInstance().loggedIn())
                    return <DefaultLayout {...props} isNegozio={!AuthService.getInstance().isUserAdmin()} key={2}/>
                  else{
                    return <Login {...props}/>
                  }
                }
              }/>

            </Switch>
          </React.Suspense>
      </BrowserRouter>

    );
  }
}

export default App;
