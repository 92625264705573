import ConfigurationSingleton from "../utility/ConfigurationSingleton";


// launch mode: REACT_APP_ENV=dev npm start

const backendServices = ConfigurationSingleton.getInstance().getBackEndPath();
const subpath = "/api/v1/"

const servicePath = backendServices+subpath

export default {
  backendServices,
  "loginEndpoint": servicePath+"user/login",
  "logoutEndpoint": servicePath+"user/logout",
  "recuperaPwdEndpoint": servicePath+"user/recuperapassword",
  "resetPwdEndpoint": servicePath+"user/resetpassword",
  "utentiSistemaEndpoint": servicePath+"user",
  "contattiEndpoint": servicePath+"contatto",
  "campagnaEndpoint": servicePath+"campagna",
  "auditingEndpoint": servicePath+"audit",
  "messaggioEndpoint": servicePath+"messaggio",
  "usersEndpoint": servicePath+"user/all",
  "userEndpoint": servicePath+"user/all",

}
